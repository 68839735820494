import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../store/auth";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import { ImSpinner } from "react-icons/im";
import API_BASE_URL from "../../utils/api";

const AdminDeletedData = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const { authorizationToken } = useAuth();

  // Function to sort users by creation date
  const sortUsersByCreationDate = (usersArray) => {
    return usersArray.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  // Debounced search functionality
  const debouncedSearch = useCallback(
    debounce((query) => {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
      setCurrentPage(0);
    }, 300),
    [users]
  );

  // Execute debounced search when searchQuery changes
  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const fetchDeletedData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/booking/deleted`, {
        headers: {
          Authorization: authorizationToken,
        },
      });

      if (response.status === 200) {
        const sortedUsers = sortUsersByCreationDate(response.data);
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      }
    } catch (error) {
      console.error("Error fetching deleted data:", error);
      toast.error("Failed to fetch deleted data");
    } finally {
      setIsLoading(false);
    }
  };

  // Pagination variables
  const offset = currentPage * usersPerPage;
  const currentPageUsers = filteredUsers.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  // Handle page click in pagination
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Initial fetch of data when component mounts
  useEffect(() => {
    fetchDeletedData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center items-center">
          <h1 className="text-4xl font-semibold text-center py-[1rem]">
            Deleted Booking Details
          </h1>
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="px-4 py-2 my-6 w-80 border border-black rounded-md focus:outline-none focus:border-gray-300"
          />
          <table className="max-w- m-auto divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  User Details
                </th>

                <th
                  scope="col"
                  className="px-[3rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Hall Name
                </th>
                <th
                  scope="col"
                  className="px-[4rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Date
                </th>

                <th
                  scope="col"
                  className="px-[3rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Recurrence EndDate
                </th>
                <th
                  scope="col"
                  className="px-[7rem] py-6 text-center text-[16px]  font-medium text-gray-500 "
                >
                  Purpose
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 max-w-11 ">
              {currentPageUsers.map((currUser) => (
                <tr key={currUser.id}>
                  <td className="px-6 text-center text-[16px]">
                    {currUser.name} <br />
                    {currUser.email} {currUser.CC} {currUser.phoneNo} <br />
                    {currUser.facilitator}
                  </td>
                  <td className="px-10 text-center text-[16px]">
                    {currUser.hallName}
                    <br />
                    {currUser.requestType}
                    <br /> Count:{currUser.count}
                  </td>
                  <td className="px-6 text-[16px] text-center">
                    {currUser.date} <br /> {currUser.startTime}
                    {" To "}
                    {currUser.endTime}
                  </td>
                  <td className="px-6 py-4 text-center text-[16px]">
                    {currUser.recurrence} <br />
                    {"Till: "}
                    {` ${currUser.recurrenceEndDate}`}
                  </td>
                  <td className="px-6 w-[1rem] max-w-11 max-h-11 text-center  overflow-y-scroll whitespace-normal">
                    {currUser.purpose}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="my-10">
            <ReactPaginate
              className="flex m-10 gap-8"
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination flex justify-center border-t border-gray-200 pt-4"
              }
              pageClassName={"page-link"}
              previousClassName={"page-link"}
              nextClassName={"page-link"}
              activeClassName={"active text-blue-500 font-bold"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDeletedData;
