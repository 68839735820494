import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center pt-[10rem] min-h-screen bg-orange-100">
      <div className="text-center">
        <h1 className="text-6xl font-extrabold text-gray-800 ">404</h1>
        <p className="mt-4 text-xl text-gray-600">Page Not Found</p>
        <p className="mt-2 text-gray-500">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="mt-6 inline-block px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
