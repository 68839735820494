import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import room from "../Images/room.jpg";
import sbclass from "../Images/SB-class.jpg";
import guestroom from "../Images/guest-room.webp";
import Repair from "../Images/Repair.png";
import committes from "../Images/committes.jpg";
import department from "../Images/department.jpg";
import email from "../Images/email.jpg";
import holiday from "../Images/holiday.jpeg";
import itsupport from "../Images/IT-support.jpg";

const cardsData = [
  {
    id: 1,
    image: room,
    title: "Room Booking System",
    path: "/calendar",
  },
  {
    id: 2,
    image: sbclass,
    title: "SB Class Schedule",
    path: "/sbclasses",
  },

  {
    id: 4,
    image: Repair,
    title: "Repair and Maintenance",
    path: "/about",
  },
  {
    id: 5,
    image: committes,
    title: "Committees",
    path: "/about",
  },
  {
    id: 6,
    image: department,
    title: "Departments Contact",
    path: "/about",
  },
  {
    id: 7,
    image: email,
    title: "Get Email Update",
    path: "/about",
  },
  {
    id: 8,
    image: holiday,
    title: "Employee Holidays 2024",
    path: "/about",
  },
  {
    id: 9,
    image: itsupport,
    title: "IT Support",
    path: "/about",
  },
];

const Home = () => {
  const [toggle, setToggle] = useState(true);

  // Original and updated words
  const originalWord1 = "CHOWPATTY";
  const updatedWord1 = "ISKCON";
  const originalWord2 = "Radha";
  const updatedWord2 = "Sri Sri";

  // Toggle the words based on the state
  const displayWord1 = toggle ? originalWord1 : updatedWord1;
  const displayWord2 = toggle ? originalWord2 : updatedWord2;

  // Effect to toggle the state in a loop
  useEffect(() => {
    const interval = setInterval(() => {
      setToggle((prev) => !prev);
    }, 3000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="p-10 bg-orange-100  pt-[10rem] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {cardsData.map(({ id, image, title, path }) => (
          <NavLink
            key={id}
            to={path}
            className="block pb-3  max-w-sm bg-white rounded-t-2xl rounded-lg border border-gray-200 shadow-md hover:bg-gray-200"
          >
            <div className="overflow-hidden rounded-t-2xl">
              <img
                src={image}
                alt={title}
                className="w-full object-cover h-80"
              />
            </div>
            <h5 className="mt-4 mb-2 pt-3  text-2xl font-bold text-center tracking-tight text-gray-900">
              {title}
            </h5>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default Home;
