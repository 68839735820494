import { Link, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../store/auth";

const SupervisorLayouts = () => {
  const { user, isLoading } = useAuth();

  // Handle loading state
  if (isLoading) {
    return <h1>Loading....</h1>;
  }

  // Redirect if user is not logged in or does not have the required role
  if (!user || (user.role !== "admin" && user.role !== "supervisor")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex min-h-screen w-full">
      {/* Sidebar */}
      <div className="bg-gray-950 text-white flex flex-col w-64 md:w-72 lg:w-80 p-0 m-0 fixed h-full overflow-y-auto">
        <header className="flex items-center justify-between border-b border-gray-800 px-6 h-16">
          <Link
            to="/supervisor/"
            className="flex items-center gap-2 text-lg font-semibold"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            <h1 className="text-white">Temple Admin</h1>
          </Link>
        </header>
        <nav>
          <div className="grid gap-8 text-lg font-medium px-6 py-8">
            {/* Additional Menu Items */}
            <div className="grid gap-2">
              <Link
                to="/supervisor/adminsbclasses"
                className="hover:text-gray-50"
              >
                SB classes
              </Link>
            </div>
            <div className="grid gap-2">
              <Link to="/" className="hover:text-gray-50">
                Home
              </Link>
            </div>
          </div>
        </nav>
      </div>

      {/* Main Content */}
      <div className="ml-64 md:ml-72 lg:ml-80 p-4 w-full">
        {/* Outlet for rendering child routes */}
        <Outlet />
      </div>
    </div>
  );
};

export default SupervisorLayouts;
