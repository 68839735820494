import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ImSpinner } from "react-icons/im";
import { toast } from "react-toastify";
import { useAuth } from "../../store/auth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import API_BASE_URL from "../../utils/api";

const AdminEmailUpdate = () => {
  const { authorizationToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const quillRef = useRef(null); // Ref for ReactQuill

  const [formData, setFormData] = useState({
    templateName: "",
    toEmail: "",
    ccEmail: "",
    subject: "",
    body: "", // HTML content as a string
  });

  const tempNames = [
    "Approval Template",
    'UndoApproved Template',
    "User Acknowledgement Template",
    "Cancellation Template",
    "User Template",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleQuillChange = (value) => {
    setFormData({
      ...formData,
      body: value,
    });
  };

  const params = useParams();

  const getEmailData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/email/template/${params.id}`,
        {
          headers: {
            Authorization: authorizationToken,
          },
        }
      );

      if (response.status === 200) {
        const { templateName, toEmail, ccEmail, subject, body } = response.data;
        setFormData({
          templateName: templateName || "",
          toEmail: toEmail || "",
          ccEmail: ccEmail || "",
          subject: subject || "",
          body: body || "",
        });
      }
    } catch (error) {
      console.log("Error fetching email data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmailData();
  }, []);

  console.log(`${API_BASE_URL}/api/email/template/${params.id}`, )

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to Update?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const { templateName, toEmail, ccEmail, subject, body } = formData;
              const config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: authorizationToken,
                },
              };
  
              const response = await axios.put(
                `${API_BASE_URL}/api/email/template/${params.id}`,
                { templateName, toEmail, ccEmail, subject, body },
                config
              );
  
              if (response.status === 200) {
                setFormData({
                  templateName: "",
                  toEmail: "",
                  ccEmail: "",
                  subject: "",
                  body: "",
                });
                toast.success("Successfully updated email settings");
              } else {
                toast.error("Update failed");
              }
            } catch (error) {
              console.error("Error updating email settings:", error);
              const errorMessage = error.response?.data?.message || "An error occurred while updating email settings";
              toast.error(errorMessage);
            } finally {
              setIsSubmitted(false);
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmitted(false);
          },
        },
      ],
    });
  };

  return (
    <>
      {isLoading || isSubmitted ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="max-w-full mx-auto p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Custom Email</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="templateName"
                className="block text-sm font-medium text-gray-700"
              >
                Template Name
              </label>
              <select
                id="templateName"
                name="templateName"
                value={formData.templateName}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              >
                <option value="">Select a Template</option>
                {tempNames.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="toEmail"
                className="block text-sm font-medium text-gray-700"
              >
                To Email
              </label>
              <input
                type="email"
                id="toEmail"
                name="toEmail"
                value={formData.toEmail}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="ccEmail"
                className="block text-sm font-medium text-gray-700"
              >
                CC Email
              </label>
              <input
                type="email"
                id="ccEmail"
                name="ccEmail"
                value={formData.ccEmail}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label
                htmlFor="body"
                className="block text-sm font-medium text-gray-700"
              >
                Body
              </label>
              <ReactQuill
                ref={quillRef}
                value={formData.body}
                onChange={handleQuillChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Enter your HTML content here"
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
              >
                Update
              </button>
            </div>
          </form>
          <div className="mt-4 p-4 border border-gray-300 rounded-md">
            <h3 className="text-lg font-semibold">Preview</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(formData.body),
              }}
              className="mt-2 p-4 border border-gray-300 rounded-md"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminEmailUpdate;
