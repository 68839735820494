import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../store/auth";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import { ImSpinner } from "react-icons/im";
import API_BASE_URL from "../../utils/api";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const { authorizationToken, user } = useAuth();

  const sortUsersByCreationDate = (usersArray) => {
    return usersArray.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  const getAllUsersData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/admin/users/getallusers`,
        {
          headers: {
            Authorization: authorizationToken,
          },
        }
      );

      if (response.status === 200) {
        const sortedUsers = sortUsersByCreationDate(response.data);
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsersData();
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    }, 300),
    [users]
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
    setCurrentPage(0);
  }, [searchQuery, debouncedSearch]);

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `${API_BASE_URL}/api/admin/users/${id}`,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 200) {
                const updatedUsers = users.filter((user) => user.id !== id);
                const sortedUsers = sortUsersByCreationDate(updatedUsers);
                setUsers(sortedUsers);
                setFilteredUsers(sortedUsers);
                toast.success("User deleted successfully");
              }
            } catch (error) {
              console.error("Error deleting user:", error);
              toast.error("Failed to delete user");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const offset = currentPage * usersPerPage;
  const currentPageUsers = filteredUsers.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full px-4 sm:px-6 lg:px-8">
          <div className="my-4">
            <input
              type="text"
              placeholder="Search by name or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">ID</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Name</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Department</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">PhoneNo</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Email</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Role</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Update</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase">Delete</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentPageUsers.map((currUser) => (
                  <tr key={currUser.id}>
                    <td className="px-4 py-2">{currUser.id}</td>
                    <td className="px-4 py-2">{currUser.name}</td>
                    <td className="px-4 py-2">{currUser.department}</td>
                    <td className="px-4 py-2">{currUser.phoneNo}</td>
                    <td className="px-4 py-2">{currUser.email}</td>
                    <td className="px-4 py-2">{currUser.role}</td>
                    <td className="px-4 py-2">
                      <Link to={`/admin/users/${currUser.id}/edit`} className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </Link>
                    </td>
                    <td className="px-4 py-2">
                      {user.id !== currUser.id && (
                        <button
                          className="text-red-600 hover:text-red-900"
                          onClick={() => handleDelete(currUser.id)}
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="my-10">
            <ReactPaginate
              className="flex gap-4 justify-center"
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeLinkClassName={"active"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminUsers;
