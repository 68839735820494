import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useAuth } from "../../store/auth";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IoTrashBinSharp } from "react-icons/io5";
import { PiCheckFatFill } from "react-icons/pi";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { ImSpinner } from "react-icons/im";
import API_BASE_URL from "../../utils/api";

const AdminBooking = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const { user, authorizationToken } = useAuth();

  const [disabledId, setDisabledId] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [isApproving, setIsApproving] = useState(false);
  const [isUndoApproving, setIsUndoApproving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const hallNames = [
    "Conference Room",
    "Bhaktivedanta Hall",
    "Bhaktisiddhanta Hall",
    "Gopalji Hall",
    "BACE Class Rooms ",
    "Laxmi Narayan Hall",
    "Bhaktivinoda Hall ",
  ];

  const hallCapacity = {
    "Conference Room": 100,
    "Bhaktivedanta Hall": 300,
    "Bhaktisiddhanta Hall": 200,
    "Gopalji Hall": 50,
    "BACE Class Rooms (Nitai room included)": 100,
    "Laxmi Narayan Hall": 75,
    "Bhaktivinoda Hall": 150,
  };

  // Function to sort users by creation date
  const sortUsersByCreationDate = (usersArray) => {
    return usersArray.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  };

  // Function to fetch conference data
  const getBookingData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/booking/createbooking`,
        {
          headers: {
            Authorization: authorizationToken,
          },
        }
      );
      // console.log(response.data);

      if (response.status === 200) {
        const sortedUsers = sortUsersByCreationDate(response.data);
        setUsers(sortedUsers);
        // console.log(sortedUsers);
        setFilteredUsers(sortedUsers);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Failed to fetch users");
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch conference data on component mount or when authorizationToken changes
  useEffect(() => {
    getBookingData();
  }, [authorizationToken]);

  // Debounced search functionality
  const debouncedSearch = useCallback(
    debounce((query) => {
      const filtered = users.filter(
        (user) =>
          user.name.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
      setCurrentPage(0);
    }, 300),
    [users]
  );

  // Execute debounced search when searchQuery changes
  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  // Function to handle user deletion
  const handleDelete = async (id) => {
    setDisabledId(id);
    setIsDeleting(true);
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this item?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `${API_BASE_URL}/api/booking/${id}`,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 200) {
                const updatedUsers = users.filter((user) => user.id !== id);
                // const updatedUsers = users.map((user) =>
                //   user.id === id ? { ...user, approved: false } : user
                // );
                const sortedUsers = sortUsersByCreationDate(updatedUsers);
                setUsers(sortedUsers);
                setFilteredUsers(sortedUsers);

                toast.success("Data deleted successfully");
              }
            } catch (error) {
              console.error("Error deleting user:", error);
              toast.error("Error during removing data");
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsDeleting(false); // Clear deleting state
              setDisabledId(null);
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsDeleting(false);
            setDisabledId(null);
          },
        },
      ],
    });
  };

  // Function to handle user approval
  const handleApprove = async (id) => {
    setIsApproving(true);
    // Check if user is already approved locally
    const userToApprove = users.find((user) => user.id === id);
    if (!userToApprove || userToApprove.approved) {
      return;
    }

    confirmAlert({
      title: "Confirm Approval",
      message: "Are you sure you want to approve this booking?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.post(
                `${API_BASE_URL}/api/booking/${id}`,
                null,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 201) {
                toast.success("Booking approved and added to calendar");

                const updatedUsers = users.map((user) =>
                  user.id === id ? { ...user, approved: true } : user
                );
                // console.log(updatedUsers);
                setUsers(updatedUsers);
                setFilteredUsers(updatedUsers);
              }
            } catch (error) {
              console.error("Error approving booking:", error);
              if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message); // Display error message from backend
              } else {
                toast.error("Error during approval");
              }
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsApproving(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsApproving(false);
          },
        },
      ],
    });
  };

  const handleUndoApprove = async (id) => {
    setIsUndoApproving(true);
    // console.log(`Undo approve triggered for user id: ${id}`); // Logging the function call

    // Check if user is already approved locally
    const userToUndoApprove = users.find((user) => user.id === id);

    if (!userToUndoApprove) {
      console.warn("User not found in local state");
      return;
    }

    if (!userToUndoApprove.approved) {
      console.warn("User is already not approved");
      return;
    }

    confirmAlert({
      title: "Confirm Approval",
      message: "Are you sure you want to UndoApprove this booking?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.post(
                `${API_BASE_URL}/api/booking/undo/${id}`,
                null,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 201) {
                const updatedUsers = users.map((user) =>
                  user.id === id ? { ...user, approved: false } : user
                );
                setUsers(updatedUsers);
                setFilteredUsers(updatedUsers);
                toast.success("Successfully undo the Approved button");
              }
            } catch (error) {
              console.error("Error approving booking:", error);
              toast.error("Error during approval");
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsUndoApproving(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsUndoApproving(false);
          },
        },
      ],
    });
  };

  // Pagination variables

  const offset = currentPage * usersPerPage;
  const currentPageUsers = filteredUsers.slice(offset, offset + usersPerPage);
  const pageCount = Math.ceil(filteredUsers.length / usersPerPage);

  // Handle page click in pagination
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    approved: false,
    undoApproved: false,
    deleted: false,
    hallNames: [],
  });

  const applyFilters = () => {
    let filtered = users;

    if (filterOptions.approved) {
      filtered = filtered.filter((user) => user.approved);
    }

    if (filterOptions.undoApproved) {
      filtered = filtered.filter((user) => !user.approved);
    }

    if (filterOptions.hallNames.length > 0) {
      filtered = filtered.filter((user) =>
        filterOptions.hallNames.includes(user.hallName)
      );
    }

    setFilteredUsers(filtered);
    setCurrentPage(0);
  };

  // Render JSX
  return (
    <>
      <div className="flex justify-end mx-10">
        <button
          onClick={() => setShowFilterPanel(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Filter
        </button>

        <div className="mx-5 mt-2">
          {user ? (
            user.role === "admin" ? (
              <Link
                to="/admin/booking/deletedata"
                className="bg-red-500 text-white px-4 py-2 w-48 text-center rounded-md hover:bg-red-600 focus:outline-none"
              >
                Show Deleted Data
              </Link>
            ) : user.role === "manager" ? (
              <Link
                to="/manager/booking/deletedata"
                className="bg-red-500 text-white px-4 py-2 w-48 text-center rounded-md hover:bg-red-600 focus:outline-none"
              >
                Show Deleted Data
              </Link>
            ) : (
              <span> Show Deleted Data</span>
            )
          ) : (
            <span> Show Deleted Data</span>
          )}
        </div>
      </div>

      {/* Filter Panel */}
      {showFilterPanel && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-start pt-10 justify-center">
          <div className="bg-white p-6 rounded-md w-80">
            <h2 className="text-xl font-semibold mb-4">Filter Options</h2>
            <div className="flex flex-col text-lg">
              <label>
                <input
                  type="checkbox"
                  checked={filterOptions.approved}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      approved: !filterOptions.approved,
                    })
                  }
                  className="mx-4"
                />
                Approved
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filterOptions.undoApproved}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      undoApproved: !filterOptions.undoApproved,
                    })
                  }
                  className="mx-4"
                />
                Undo Approved
              </label>
              <div className="mt-4">
                <h3 className="text-lg font-semibold">Hall Names</h3>
                {hallNames.map((hall) => (
                  <label key={hall} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={filterOptions.hallNames.includes(hall)}
                      onChange={() =>
                        setFilterOptions((prev) => ({
                          ...prev,
                          hallNames: prev.hallNames.includes(hall)
                            ? prev.hallNames.filter((h) => h !== hall)
                            : [...prev.hallNames, hall],
                        }))
                      }
                      className="mx-2"
                    />
                    {hall}
                  </label>
                ))}
              </div>
              {/* <label>
                <input
                  type="checkbox"
                  checked={filterOptions.deleted}
                  onChange={() =>
                    setFilterOptions({
                      ...filterOptions,
                      deleted: !filterOptions.deleted,
                    })
                  }
                  className="mx-4"
                />
                Deleted
              </label> */}
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => {
                    applyFilters();
                    setShowFilterPanel(false);
                  }}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Apply Filters
                </button>
                <button
                  onClick={() => setShowFilterPanel(false)}
                  className="ml-2 bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoading || isApproving || isUndoApproving || isDeleting ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center items-center">
          <h1 className="text-4xl font-semibold text-center py-[1rem]">
            User Booking Details
          </h1>
          <div className="my-4 flex gap-4 items-center">
            <input
              type="text"
              placeholder="Search by name or email"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="px-4 py-2 w-80 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500"
            />
            {user ? (
              user.role === "admin" ? (
                <Link
                  to="/admin/booking/bookingcalender"
                  className="bg-blue-500 text-white px-4 py-2 w-48 text-center rounded-md hover:bg-blue-600 focus:outline-none"
                >
                  Add To Calendar
                </Link>
              ) : user.role === "manager" ? (
                <Link
                  to="/manager/booking/bookingcalender"
                  className="bg-blue-500 text-white px-4 py-2 w-48 text-center rounded-md hover:bg-blue-600 focus:outline-none"
                >
                  Add To Calendar
                </Link>
              ) : (
                <span> Add To Calendar</span>
              )
            ) : (
              <span> Add To Calendar</span>
            )}
          </div>

          <table className="max-w- m-auto divide-y divide-gray-200 ">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  User Details
                </th>

                <th
                  scope="col"
                  className="px-[3rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Hall Name
                </th>
                <th
                  scope="col"
                  className="px-[4rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Date
                </th>

                <th
                  scope="col"
                  className="px-[3rem] py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Recurrence EndDate
                </th>
                <th
                  scope="col"
                  className="px-[7rem] py-6 text-center text-[16px]  font-medium text-gray-500 "
                >
                  Purpose
                </th>

                <th
                  scope="col"
                  className="px-4 py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Approved
                </th>

                <th
                  scope="col"
                  className="px-4 py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Edit
                </th>
                <th
                  scope="col"
                  className="px-4 py-6 text-center text-[16px] font-medium text-gray-500 "
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 max-w-11 ">
              {currentPageUsers.map((currUser) => (
                <tr key={currUser.id}>
                  <td className="px-6 text-center text-[16px]">
                    {currUser.name} <br />
                    {currUser.email} <br /> <strong>CCEmail:-</strong>
                    {currUser.CC} <br /> <strong>phoneNo:-</strong>
                    {currUser.phoneNo} <br />
                    <strong>Facilitator:-</strong> {currUser.facilitator}
                  </td>
                  <td className="px-10 text-center text-[16px]">
                    {currUser.hallName}
                    <br />
                    {currUser.requestType}
                    <br /> <strong>Count:-</strong>
                    {currUser.count > hallCapacity[currUser.hallName] ? (
                      <div className="bg-red-600 text-white">
                        {currUser.count}
                      </div>
                    ) : (
                      <div>{currUser.count}</div>
                    )}
                  </td>
                  <td className="px-6 text-[16px] text-center">
                    {currUser.date} <br />
                    {currUser.startTime}
                    {" To "}
                    {currUser.endTime}
                  </td>
                  <td className="px-6 py-4 text-center text-[16px]">
                    {currUser.recurrence} <br />
                    {"Till: "}
                    {` ${currUser.recurrenceEndDate}`}
                  </td>
                  <td className="px-6 w-[10%] max-w-11 h-20 max-h-20 text-center overflow-auto whitespace-normal">
  {currUser.purpose}
</td>


                  <td className="px-2 py-4 whitespace-nowrap">
                    {currUser.approved ? (
                      <button
                        className=" text-indigo-600 hover:text-indigo-900"
                        onClick={() => handleUndoApprove(currUser.id)}
                      >
                        {" "}
                        <FaRegCircleXmark className="w-8 h-8 ml-6 m-auto" />
                        <div className="item-center ml-6 mt-2 ">
                          {" "}
                          {currUser.approvedby == null
                            ? `${currUser.approvedby}`
                            : `${currUser.approvedby}`}
                          <br /> {/* {`${currUser.updatedAt}`} */}
                        </div>
                      </button>
                    ) : (
                      <button
                        className=" text-green-600 hover:text-green-900"
                        onClick={() => handleApprove(currUser.id)}
                      >
                        <PiCheckFatFill className="w-8 h-8 m-auto ml-7" />
                      </button>
                    )}
                  </td>
                  <td className="px-2 relative">
                    <button
                      className={`${
                        currUser.approved
                          ? " text-indigo-600 hover:text-indigo-900"
                          : " text-green-600 hover:text-green-900"
                      }`}
                      disabled={currUser.approved}
                      onMouseEnter={() => setIsHovered(currUser.id)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      {user && !currUser.approved ? (
                        user.role === "admin" ? (
                          <Link to={`/admin/booking/${currUser.id}/edit`}>
                            <FaEdit className="w-8 h-8 m-auto ml-6 mt-2" />
                            {isHovered === currUser.id && (
                              <div className="absolute left- transform -translate-x-1/2 top-full mt-2 bg-white p-2 rounded shadow-lg z-10 max-w-xs break-words whitespace-normal">
                                {currUser.reasonToEdit}
                              </div>
                            )}
                            <div className="item-center mt-3 ">
                              {currUser.editedby !== null
                                ? `${currUser.editedby}`
                                : ""}
                            </div>
                          </Link>
                        ) : user.role === "manager" ? (
                          <Link to={`/manager/booking/${currUser.id}/edit`}>
                            <FaEdit className="w-8 h-8 m-auto ml-6 mt-2" />
                            <div className="item-center mt-3 ">
                              {currUser.editedby !== null
                                ? `${currUser.editedby}`
                                : ""}
                            </div>
                          </Link>
                        ) : (
                          <span>
                            <FaEdit className="w-8 h-8 m-auto ml-6 mt-2" />
                            <div className="item-center mt-3 ">
                              {currUser.editedby !== null
                                ? `${currUser.editedby}`
                                : ""}
                            </div>
                          </span>
                        )
                      ) : (
                        <span className="text-gray-500 hover:text-gray-400">
                          <FaEdit className="w-8 h-8 m-auto ml-6 mt-2" />
                        </span>
                      )}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      className="text-red-600 hover:text-red-900"
                      onClick={() => handleDelete(currUser.id)}
                    >
                      <IoTrashBinSharp className="w-8 h-8" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="my-10">
            <ReactPaginate
              className="flex m-10 gap-8"
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination flex justify-center border-t border-gray-200 pt-4"
              }
              pageClassName={"page-link"}
              previousClassName={"page-link"}
              nextClassName={"page-link"}
              activeClassName={"active text-blue-500 font-bold"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AdminBooking;
