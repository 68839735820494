import { Link } from "react-router-dom";
import {
  UsersIcon,
  CalendarIcon,
  BookOpenIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { MdOutlineMail } from "react-icons/md";
import { HiOutlineLogin } from "react-icons/hi";

import React from "react";

function AdminDashboard() {
  const Halls = [
    {
      title: "Conference Room",
      path: "/admin/conference",
    },
    {
      title: "Bhaktivedanta Hall",
      path: "/admin/bhaktivedanta",
    },
    {
      title: "Bhaktisiddhanta Hall",
      path: "/admin/bhaktisiddhanta",
    },
    {
      title: "Gopalji Hall",
      path: "/admin/gopalji",
    },
    {
      title: "BACE Class Rooms",
      path: "/admin/bace",
    },
    {
      title: "Laxmi Narayan Hall",
      path: "/admin/laxminarayan",
    },
    {
      title: "Bhaktivinoda Hall",
      path: "/admin/bhaktivinoda",
    },
  ];

  return (
    <main className="flex-1 overflow-auto p-6 md:p-8 lg:p-10">
      {/* Section: Summary Cards */}
      <section className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
        {/* Placeholder Cards (You can add content based on your data) */}
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <UsersIcon className="w-8 h-8  text-gray-500 dark:text-gray-400" />
          <Link
            to="/admin/users"
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">Users</div>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <CalendarIcon className="w-8 h-8  text-gray-500 dark:text-gray-400" />
          <Link
            to="/admin/booking"
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">Hall Bookings</div>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <BookOpenIcon className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          <Link
            to="/admin/adminsbclasses"
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">SB Classes</div>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <MdOutlineMail className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          <Link
            to="/admin/adminemail "
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">Email Template</div>
        </div>{" "}
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <UserIcon className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          <Link
            to="/admin/signup"
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">SignUp Page</div>
        </div>{" "}
        <div className="bg-white shadow-md rounded-lg p-6 flex flex-col justify-between">
          <HiOutlineLogin className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          <Link
            to="/"
            className="text-lg flex justify-end items-start mt-[-2rem]  font-medium text-primary"
          >
            View
          </Link>
          <div className="text-2xl pt-10 font-bold">Home</div>
        </div>
      </section>
    </main>
  );
}

export default AdminDashboard;
