import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ImSpinner } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../../utils/api";

const AdminSBClasses = () => {
  const { authorizationToken } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [formData, setFormData] = useState({
    sheetLink: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpload(true);

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to submit the Google Sheet link?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: authorizationToken,
                },
              };

              const response = await axios.post(
                `${API_BASE_URL}/api/sbclasses/sbclassesadding`,
                { sheetLink: formData.sheetLink },
                config
              );

              if (response.status === 201) {
                toast.success(
                  "Successfully added SB classes events from Google Sheet"
                );
                navigate("/admin/adminsbclasses"); // Redirect to home page after successful booking
              } else {
                toast.error("Failed to add SB classes events");
              }

              // Clear form data after submission
              setFormData({
                sheetLink: "",
              });
            } catch (error) {
              const errorMessage =
                error.response?.data?.message ||
                "An error occurred during booking";
              console.error("Error:", errorMessage);
              toast.error(errorMessage);
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsUpload(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsUpload(false);
          },
        },
      ],
    });
  };

  const calendarSrc =
    "https://calendar.google.com/calendar/embed?src=cb9b5e3062c118b52e0d1de96518a0eb04d9bab3fb0714782aa87ace499665c4%40group.calendar.google.com&ctz=Asia%2FKolkata";
  return (
    <>
      {isLoading || isUpload ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="bg-white w-full">
          <h1 className="text-4xl font-semibold text-center py-[2rem]">
            SB Classes
          </h1>
          <div className="flex flex-col md:flex-row p-4">
            <div className="md:w-1/2 md:pr-4">
              <h2 className="text-2xl font-semibold mb-4 text-center">
                Add SB classes Event
              </h2>
              <form
                onSubmit={handleSubmit}
                className="shadow-md p-6 rounded-lg hover:shadow-2xl transition-shadow duration-300"
              >
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label htmlFor="sheetLink" className="block mb-1">
                      Google Sheet Link
                    </label>
                    <input
                      type="text"
                      id="sheetLink"
                      name="sheetLink"
                      value={formData.sheetLink}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-1"
                      required
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mt-4 block mx-auto"
                >
                  Submit
                </button>
              </form>
            </div>
            <div className="md:w-1/2 md:pl-4 mt-4 md:mt-0">
              <iframe
                src={calendarSrc}
                className="border-0 w-full max-w-full md:max-w-4xl h-96 md:h-[600px] shadow-lg rounded-lg mx-auto"
                frameBorder="0"
                scrolling="no"
                title="Google Calendar"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminSBClasses;
