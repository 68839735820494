import { Link, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../store/auth";
import { toast } from "react-toastify";
import { useState } from "react";

const AdminLayouts = () => {
  const { user, isLoading } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(true);

  // Handle loading state
  if (isLoading) {
    return <h1>Loading....</h1>;
  }

  // Redirect if user is not logged in or not an admin
  if (!user || user.role !== "admin") {
    toast.warning("Only for admin");
    return <Navigate to="/" />;
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <div className="flex min-h-screen w-full">
        {/* Sidebar */}
        <div
          className={`bg-gray-950 text-white flex flex-col fixed h-full overflow-y-auto transition-transform transform ${
            drawerOpen ? "translate-x-0" : "-translate-x-full"
          } w-64 md:w-72 lg:w-80 z-30`}
        >
          <header className="flex items-center justify-between border-b border-gray-800 px-6 h-16">
            <Link
              to="/admin/"
              className="flex items-center gap-2 text-lg font-semibold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <h1 className="text-white py-6 px-4">Temple Admin</h1>
            </Link>
            <button onClick={toggleDrawer} className="focus:outline-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </header>
          <nav>
            <div className="grid gap-8 text-lg font-medium overflow-auto px-6 py-8">
              <Link to="/admin/dashboard" className="hover:text-gray-50">
                Dashboard
              </Link>
              <Link to="/admin/users" className="hover:text-gray-50">
                Users
              </Link>
              <div className="grid gap-2 ">
                <Link to="/admin/booking" className="hover:text-gray-50">
                  Hall Booking Details
                </Link>
              </div>
              <div className="grid gap-2 ">
                <Link to="/admin/adminsbclasses" className="hover:text-gray-50">
                  SB classes
                </Link>
              </div>
              <div className="grid gap-2 ">
                <Link to="adminemail" className="hover:text-gray-50">
                  Email Template
                </Link>
              </div>
              <div className="grid gap-2 ">
                <Link to="signup" className="hover:text-gray-50">
                  Signup page
                </Link>
              </div>
              <div className="grid gap-2 ">
                <Link to="/" className="hover:text-gray-50">
                  Home
                </Link>
              </div>
            </div>
          </nav>
        </div>

        {/* Main Content */}
        <div
          className={`transition-transform transform w-full ${
            drawerOpen ? "ml-64 md:ml-72 lg:ml-80" : ""
          } p-4`}
        >
          {/* Outlet for rendering child routes */}
          <Outlet />
        </div>

        {/* Drawer Toggle Button */}
        <button
          onClick={toggleDrawer}
          className="fixed top-4 left-4 z-50 bg-gray-800 text-white p-2 rounded-md focus:outline-none"
        >
          {drawerOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default AdminLayouts;
