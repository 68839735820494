import { useEffect, useState } from "react";
import { useAuth } from "../store/auth";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../utils/api";

const ProfileEdit = () => {
  const { authorizationToken } = useAuth();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    department: "",
    phoneNo: "",
  });

  const [error, setError] = useState("");

  const getSingleProfileData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/users/profile`, {
        headers: {
          Authorization: authorizationToken,
        },
      });

      if (response.status === 200) {
        setFormData(response.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProfileData();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm to Update",
      message: "Are you sure you want to update this user profile data?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.put(
                `${API_BASE_URL}/api/users/profile`,
                formData,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 200) {
                setFormData(response.data.user || {});
                // console.log(response.data.user);
              }
              toast.success("Updated Successfully");
            } catch (error) {
              console.log(error);
              toast.error("An error occurred while updating user data");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // Do nothing if user clicks No
          },
        },
      ],
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-orange-100 p-4">
      <form
        onSubmit={handleSubmit}
        className="bg-white w-full max-w-lg p-8 shadow-md rounded-md"
      >
        <div>
          <h1 className="block text-gray-700 text-xl font-bold mb-8 text-center">
            Update Profile Data
          </h1>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            name:
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInput}
            className={`border ${
              error.name ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-full focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.name} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInput}
            className={`border ${
              error.email ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-full focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.email} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="department"
          >
            Department:
          </label>
          <input
            type="text"
            name="department"
            value={formData.department}
            onChange={handleInput}
            className={`border ${
              error.department ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-full focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.department} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="phoneNo"
          >
            Phone No:
          </label>
          <input
            type="text"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleInput}
            className={`border ${
              error.phoneNo ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-full focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.phoneNo} </div>
        </div>

        <button
          type="submit"
          className="bg-black text-white py-2 px-8 my-3 rounded-full hover:bg-gray-800 focus:outline-none focus:bg-gray-800 w-full"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default ProfileEdit;
