import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API_BASE_URL from "../utils/api";

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState("verifying"); // 'verifying', 'success', 'error'
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {

    // If no token is present in the URL, show an error
    if (!token) {
      setVerificationStatus("error");
      return;
    }
 
    console.log(token);
    
    // Verify the email using the token
    const verifyEmail = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/users/verifyemail/${token}`);
        if (response.status === 200) {
          setVerificationStatus("success");
        } else {
          setVerificationStatus("error");
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        setVerificationStatus("error");
      }
    };

    verifyEmail();
  }, []);

  const handleRedirect = () => {
    navigate("/login"); // Redirect to login or desired route
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        {verificationStatus === "verifying" && <p>Verifying your email...</p>}
        {verificationStatus === "success" && (
          <>
            <p>Your email has been successfully verified!</p>
            <button onClick={handleRedirect} className="btn btn-primary mt-4">
              Go to Login
            </button>
          </>
        )}
        {verificationStatus === "error" && (
          <>
            <p>Failed to verify your email. The token may be invalid or expired.</p>
            <button onClick={handleRedirect} className="btn btn-primary mt-4">
              Go to Login
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
