import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../store/auth";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { ImSpinner } from "react-icons/im";
import API_BASE_URL from "../../utils/api";

function AdminEmail() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, authorizationToken } = useAuth();

  const [formData, setFormData] = useState([]);

  // Function to fetch email data based on templateName
  const getEmailData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/email/customemail/`,
        {
          headers: {
            Authorization: authorizationToken,
          },
        }
      );

      if (response.status === 200) {
        setFormData(response.data); // Assuming response.data is an array
      }
    } catch (error) {
      console.log("Error fetching email data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch default email data on component mount
    getEmailData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center items-center">
          <h1 className="text-4xl font-semibold text-center py-[1rem]">
            Email Template
          </h1>
          <table className="w-full m-auto divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-6 text-center text-[16px] font-medium text-gray-500"
                >
                  Id
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-center text-[16px] font-medium text-gray-500"
                >
                  Template Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-6 text-center text-[16px] font-medium text-gray-500"
                >
                  Update Template
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 max-w-11">
              {formData.map((currUser) => (
                <tr key={currUser.id}>
                  <td className="px-6 text-center text-[16px]">
                    {currUser.id}
                  </td>
                  <td className="px-6 text-center text-[16px]">
                    {currUser.templateName}
                  </td>
                  <td className="px-6 text-center text-[16px]">
                    <button>
                      {user ? (
                        user.role === "admin" ? (
                          <Link to={`/admin/adminemail/${currUser.id}/update`}>
                            <FaEdit className="w-8 h-8 m-auto ml-6  mt-2" />
                          </Link>
                        ) : user.role === "manager" ? (
                          <Link
                            to={`/manager/adminemail/${currUser.id}/update`}
                          >
                            <FaEdit className="w-8 h-8 m-auto ml-6  mt-2" />
                          </Link>
                        ) : (
                          <span>
                            <FaEdit className="w-8 h-8 m-auto  ml-6 mt-2" />
                          </span>
                        )
                      ) : (
                        <span className="text-gray-500 hover:text-gray-400">
                          <FaEdit className="w-8 h-8 m-auto ml-6 mt-2" />
                        </span>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default AdminEmail;
