import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../store/auth";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { ImSpinner } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../../utils/api";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";


function Signup() {
  const { authorizationToken } = useAuth();
  const navigate = useNavigate();

  // const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const departments = [
    "Accounts",
    "Back to Godhead",
    "Books Department",
    "Brahmacari Retreat Team",
    "Care Counsellor Board",
    "COM Secretarait",
    "Devotee Care",
    "Digital Transformation Team",
    "Education Board",
    "Festivals",
    "Finance Committee",
    "Finance Council Working Committee",
    "Guest Seva",
    "Human Resource Dept",
    "ISKCON Youth Services (IYS)",
    "IT Support",
    "Kirtan Council",
    "Kitchen",
    "Matchless Gifts",
    "Online Courses",
    "Openings",
    "Parth Seva",
    "RGM Secretarait",
    "Sabbatical Care",
    "Satellite Preaching",
    "Security",
    "Seva Sthan",
    "Social Media",
    "Subscribe Back to Godhead",
    "Temple Council",
    "Temple Council Working Committee",
    "Tours",
    "Website Team",
  ];

  const [formData, setFormData] = useState({
    name: "",
    department: "",
    phoneNo: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  const formValidation = () => {
    const errors = {};
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmited(true);

    const validationErrors = formValidation();

    if (
      !formData.name ||
      !formData.department ||
      !formData.phoneNo ||
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      toast.error("All fields are required");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords does not match");
      return;
    }

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            if (Object.keys(validationErrors).length > 0) {
              setError(validationErrors);
            } else {
              try {
                const config = {
                  headers: {
                    Authorization: authorizationToken,
                  },
                };
                const response = await axios.post(
                  `${API_BASE_URL}/api/users/createUser`,
                  formData,
                  config
                );

                if (response.status === 201) {
                  setFormData(response.data);
                  toast.success(
                    "Signup successful. Please check mail for email verification."
                  );
                  navigate("/admin/");
                } else {
                  toast.error("Signup failed");
                }
                setError("");
                setFormData({
                  name: "",
                  department: "",
                  phoneNo: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                });
              } catch (error) {
                if (error.response && error.response.status === 404) {
                  toast.error("User already exists");
                } else if (error.response && error.response.data) {
                  toast.error(
                    error.response.data.error ||
                      "Something went wrong, try again"
                  );
                } else {
                  toast.error("Something went wrong, try again");
                }
              } finally {
                setIsConfirmLoading(false); // Clear loading state
                setIsSubmited(false); // Clear approving state
              }
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmited(false);
          },
        },
      ],
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  return (
    <>
       <div className="flex items-center justify-center min-h-screen bg-white">
          <form
            onSubmit={handleSubmit}
            className="bg-white w-[450px] p-4 mt-[4rem] mb-[3rem] rounded-lg transform transition-all  shadow-md hover:shadow-2xl duration-300"
          >
            <h1  className="text-gray-500 text-3xl font-extrabold text-center mb-6">
              Signup Page
            </h1>

            <div className="flex flex-wrap -mx-2 ">
              <div className="w-[80%] m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name:
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInput}
                  className={`border ${
                    error.name ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                />
                <div className="text-red-500 my-2">{error.name}</div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-2 ">
              <div className="w-[80%] m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="department"
                >
                  Department:
                </label>
                <select
                  type="text"
                  name="department"
                  value={formData.department}
                  onChange={handleInput}
                  className={`border ${
                    error.department ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                >
                  <option value="">Select Department</option>
                  {departments.map((dept) => (
                    <option key={dept} value={dept}>
                      {dept}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-wrap -mx-2 ">
              <div className="w-[80%] m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phoneNo"
                >
                  PhoneNo:
                </label>
                <input
                  type="text"
                  name="phoneNo"
                  value={formData.phoneNo}
                  onChange={handleInput}
                  className={`border ${
                    error.phoneNo ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                />
                <div className="text-red-500 my-2">{error.phoneNo}</div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-2">
              <div className="w-[80%] m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInput}
                  className={`border ${
                    error.email ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                />
                <div className="text-red-500 my-2">{error.email}</div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-2">
              <div className="w-[80%] relative m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password:
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInput}
                  className={`border ${
                    error.password ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                />
                <div className="absolute inset-y-0 right-0 pt-7 flex items-center text-lg leading-5">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none pb-2 w-8 h-8"
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                </div>
                <div className="text-red-500 my-2">{error.password}</div>
              </div>
            </div>

            <div className="flex flex-wrap -mx-2 ">
              <div className="w-[80%] relative m-auto px-2 mb-1">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm Password:
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInput}
                  className={`border ${
                    error.confirmPassword ? "border-red-500" : "border-gray-300"
                  } px-4 py-1 w-full focus:outline-none focus:border-black`}
                />
                <div className="text-red-500 my-2">{error.confirmPassword}</div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-black text-white py-2 px-8 my-3 mx-[10rem] rounded-full hover:bg-gray-800 focus:outline-none focus:bg-gray-800"
            >
              Submit
            </button>

            <div className="pt-4 text-center">
              <div className=" text-gray-400">
                Already User?{" "}
                <NavLink to="/login" className="text-black font-semibold">
                  Login here
                </NavLink>
              </div>
            </div>
          </form>
        </div>
    </>
  );
}

export default Signup;
