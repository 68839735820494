import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { ImSpinner } from "react-icons/im";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../utils/api";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams(); // Token from URL parameter
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmited(true);

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await axios.post(
                `${API_BASE_URL}/api/users/resetpassword/${token}`,
                {
                  password,
                }
              ); // Sending token and password
              toast.success("Password reset successful");
              navigate("/login");
            } catch (error) {
              toast.error(
                error.response?.data?.message || "Something went wrong"
              );
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsSubmited(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmited(false);
          },
        },
      ],
    });
  };

  return (
    <>
      {isLoading || isSubmited ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center sm:pt-[3rem] h-screen bg-orange-100 px-4">
          <div className="bg-white shadow-md w-full max-w-md sm:w-[80%]  md:w-[60%] lg:w-[40%] xl:w-[30%] rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Reset Password
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 relative">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  New Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="absolute inset-y-0 right-0 pr-2 pt-7 flex items-center text-lg leading-5">
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="focus:outline-none"
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                </div>
              </div>
              <div className="mb-4 relative">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm New Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <div className="absolute inset-y-0 right-0 pr-2 pt-7 flex items-center text-lg leading-5">
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="focus:outline-none"
                  >
                    {showConfirmPassword ? (
                      <AiFillEyeInvisible />
                    ) : (
                      <AiFillEye />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-center mt-6">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
