import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import { toast } from "react-toastify";
import { useAuth } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { ImSpinner } from "react-icons/im";
import API_BASE_URL from "../utils/api";

const BookingForm = () => {
  const { authorizationToken } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    facilitator: "",
    email: "",
    CC: "",
    requestType: "",
    hallName: "",
    date: "",
    startTime: "",
    endTime: "",
    count: "",
    purpose: "",
    recurrence: "",
    recurrenceEndDate: "",
  });

  // Use an empty array as default if formData.CC is undefined or empty
  const ccEmail = (formData.CC || "").split(',').map(email => email.trim());

  // Check if endTime is beyond 9:30 PM
const isEndTimeBeyondCutoff = (endTime, date) => {
  const cutoffTime = moment(`${date} 21:30`, 'DD-MM-YYYY HH:mm');
  const parsedEndTime = moment(`${date} ${endTime}`, 'DD-MM-YYYY HH:mm');
  return parsedEndTime.isAfter(cutoffTime);
};

  const hallOptions = [
    "Conference Room",
    "Bhaktivedanta Hall",
    "Bhaktisiddhanta Hall",
    "Gopalji Hall",
    "BACE Class Rooms (Nitai room included)",
    "Laxmi Narayan Hall",
    "Bhaktivinoda Hall",
  ];

  const requestType = [
    "Govindas Booking",
    "BACE Educational Course",
    "Preaching Program",
    "Meeting",
    "Festival Preparations ",
  ];

  const recurrenceOptions = ["Daily", "Weekly", "Monthly"];

  const hallCapacity = {
    "Conference Room": 100,
    "Bhaktivedanta Hall": 300,
    "Bhaktisiddhanta Hall": 200,
    "Gopalji Hall": 50,
    "BACE Class Rooms (Nitai room included)": 100,
    "Laxmi Narayan Hall": 75,
    "Bhaktivinoda Hall": 150,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // If the field being updated is 'hallName', update the 'count' field based on the selected hall
      if (name === "hallName") {
        updatedData.count = hallCapacity[value] || "";
      }

      return updatedData;
    });
  };

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const fetchUserProfile = async () => {
    try {
      const config = {
        headers: {
          Authorization: authorizationToken,
        },
      };
      const response = await axios.get(
        `${API_BASE_URL}/api/users/profile`,
        config
      );

      if (response.status === 200) {
        setFormData(response.data.user);
      }
    } catch (error) {
      console.log("Error fetching user profile");
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmited(true);

    if (formData.recurrence && !formData.recurrenceEndDate) {
      toast.error("Please Select Recurrence End Date .");
      setIsSubmited(false);
      return;
    }

    if (formData.count > hallCapacity[formData.hallName]) {
      toast.warning("The hall capacity is exceeded")
    }

    if (isEndTimeBeyondCutoff(formData.endTime, formData.date)) {
      toast.warning("Booking end time cannot be later than 9:30 PM");
    }

    try {
      const {
        name,
        phoneNo,
        facilitator,
        email,
        CC,
        requestType,
        hallName,
        date,
        startTime,
        endTime,
        count,
        purpose,
        recurrence,
        recurrenceEndDate,
      } = formData;

      const formattedDate = formatDateToDDMMYYYY(date);
      const formattedRecurrenceEndDate = recurrenceEndDate
        ? formatDateToDDMMYYYY(recurrenceEndDate)
        : null;

      const config = {
        headers: {
          Authorization: authorizationToken,
        },
      };

      console.log(`BOOKING ${API_BASE_URL}/api/booking/createbooking`);

      const response = await axios.post(
        `${API_BASE_URL}/api/booking/createbooking`,
        {
          name,
          phoneNo,
          facilitator,
          email,
          CC:ccEmail,
          requestType,
          hallName,
          date: formattedDate,
          startTime,
          endTime,
          count,
          purpose,
          recurrence,
          recurrenceEndDate: formattedRecurrenceEndDate,
        },
        config
      );

      if (response.status === 201) {
        setFormData({
          name: "",
          phoneNo: "",
          facilitator: "",
          email: "",
          CC: "",
          requestType: "",
          hallName: "",
          date: "",
          startTime: "",
          endTime: "",
          count: "",
          purpose: "",
          recurrence: "",
          recurrenceEndDate: "",
        });
        toast.success("Successfully received Booking Information");
        navigate("/bookingform"); // Redirect to home page after successful booking
      } else {
        toast.error("Booking failed");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred during booking";
      console.error("Error:", errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsConfirmLoading(false); // Clear loading state
      setIsSubmited(false); // Clear approving state
    }
  };

  return (
    <>
      <div className="bg-orange-100 pt-12 sm:pt-[4rem] pb-24 md:pb-12">
        <h1 className="text-4xl font-semibold text-center py-10 md:py-[4rem]">
          Request Booking Form
        </h1>
        {isLoading || isSubmited ? (
          <div className="flex justify-center items-center h-screen">
            <div className="spinner-border text-primary" role="status">
              <ImSpinner className="w-10 h-10 animate-spin" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center p-4 md:flex-row m-auto ">
            <div className="md:w-1/2 md:pr-4">
              <form
                onSubmit={handleSubmit}
                className="shadow-md p-6 item-center m-auto rounded-lg hover:shadow-2xl transition-shadow duration-300"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="name" className="block mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phoneNo" className="block mb-1">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNo"
                      name="phoneNo"
                      value={formData.phoneNo}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="block mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="CC" className="block mb-2">
                      CC Emails (comma-separated):
                    </label>
                    <input
                      type="text"
                      id="CC"
                      name="CC"
                      value={formData.CC}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="facilitator" className="block mb-1">
                      Facilitator's Name
                    </label>
                    <input
                      type="text"
                      id="facilitator"
                      name="facilitator"
                      value={formData.facilitator}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="date" className="block mb-1">
                      Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="startTime" className="block mb-1">
                      Start Time
                    </label>
                    <input
                      type="time"
                      id="startTime"
                      name="startTime"
                      value={formData.startTime}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="endTime" className="block mb-1">
                      End Time
                    </label>
                    <input
                      type="time"
                      id="endTime"
                      name="endTime"
                      value={formData.endTime}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="hallName" className="block mb-1">
                    Hall Name
                  </label>
                  <select
                    id="hallName"
                    name="hallName"
                    value={formData.hallName}
                    onChange={handleChange}
                    className="w-full border block border-gray-300 rounded px-4 py-2"
                    required
                  >
                    <option value="">Select Hall</option>
                    {hallOptions.map((hall) => (
                      <option key={hall} value={hall}>
                        {hall}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="count" className="block mb-1">
                      Count
                    </label>
                    <input
                      type="text"
                      id="count"
                      name="count"
                      value={formData.count}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="requestType" className="block mb-1">
                      Request Type
                    </label>
                    <select
                      id="requestType"
                      name="requestType"
                      value={formData.requestType}
                      onChange={handleChange}
                      className="w-full border block border-gray-300 rounded px-4 py-2"
                      required
                    >
                      <option value="">Select </option>
                      {requestType.map((req) => (
                        <option key={req} value={req}>
                          {req}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="recurrence" className="block mb-1">
                      Recurrence
                    </label>
                    <select
                      id="recurrence"
                      name="recurrence"
                      value={formData.recurrence}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                    >
                      <option value="">None</option>
                      {recurrenceOptions.map((recurrence) => (
                        <option key={recurrence} value={recurrence}>
                          {recurrence}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-1">
                    <label className="block mb-1">Recurrence End Date</label>
                    <input
                      type="date"
                      id="recurrenceEndDate"
                      name="recurrenceEndDate"
                      value={formData.recurrenceEndDate}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-1"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="purpose" className="block mb-1">
                    Purpose
                  </label>
                  <textarea
                    type="textarea"
                    id="purpose"
                    name="purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    type="submit"
                    className="bg-orange-200 text-white rounded px-6 py-2 hover:bg-orange-600 transition-colors duration-300"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookingForm;
