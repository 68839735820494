import React from "react";

const SBClasses = () => {
  const calendarSrc =
    "https://calendar.google.com/calendar/embed?src=cb9b5e3062c118b52e0d1de96518a0eb04d9bab3fb0714782aa87ace499665c4%40group.calendar.google.com&ctz=Asia%2FKolkata";
  return (
    <div className="bg-orange-100 w-full pt-[5rem] ">
      <h1 className="text-4xl font-semibold text-center py-[2rem]">
        SB Classes Schedule
      </h1>
      <div className="flex flex-col md:flex-row p-4">
        <div className="md:w-full md:px-10  md:h-full md:mt-0">
          <iframe
            src={calendarSrc}
            className="border-0 w-full max-w-full md:max-w-[1100px] m-auto h-96 md:h-[650px] shadow-lg rounded-lg "
            frameBorder="0"
            scrolling="no"
            title="Google Calendar"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SBClasses;
