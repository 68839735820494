

function validateUrl(url) {
  try {
    new URL(url); // This will throw if the URL is invalid
    return true;
  } catch (e) {
    console.error("Invalid URL:", url, e);
    return false;
  }
}

const API_BASE_URL = 
// "https://admin.dev.ssrgbs.com"
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL
    : "http://localhost:5001";

    const Frontend = process.env.REACT_APP_FRONTEND_URL

console.log("NODE_ENV:", process.env.NODE_ENV);
console.log("REACT_APP_API_BASE_URL:", process.env.REACT_APP_API_BASE_URL);
console.log("API_BASE_URL:", API_BASE_URL);
console.log("env:", process.env);
console.log("Frontend:", `${Frontend}`);

if (!validateUrl(API_BASE_URL)) {
  alert(
    "The backend URL is invalid. Please check your environment variables or configuration."
  );
}

export default API_BASE_URL;
