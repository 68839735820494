import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../store/auth";
import { confirmAlert } from "react-confirm-alert";
import { ImSpinner } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../utils/api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { authorizationToken } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmited(true);

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const config = {
                headers: {
                  Authorization: authorizationToken,
                },
              };

              const response = await axios.post(
                `${API_BASE_URL}/api/users/forgotpassword`,
                { email },
                config
              );

              if (response.status === 200) {
                toast.success("Reset Password Link is sent to your email");
                setEmail(""); // Clear email input after submission
              }
            } catch (error) {
              const errorMessage =
                error.response && error.response.data.message
                  ? error.response.data.message
                  : "User not found ";
              console.error("Error:", errorMessage); // Log the error to the console
              toast.error(errorMessage);
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsSubmited(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmited(false);
          },
        },
      ],
    });
  };

  return (
    <>
      {isLoading || isSubmited ? (
        <div className="flex justify-center items-center h-screen">
          <ImSpinner className="w-10 h-10 animate-spin" />
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen bg-orange-100 px-4">
          <div className="bg-white shadow-md w-full max-w-md sm:w-[80%] md:w-[60%] lg:w-[40%] xl:w-[30%] rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Forgot Password
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex items-center justify-center mt-6">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                  disabled={isLoading} // Disable button while loading
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
