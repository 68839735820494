import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Logout from "./components/Logout";
import Home from "./pages/Home";
import AdminLayouts from "./components/layouts/AdminLayouts";
import AdminUsers from "./components/Admin/AdminUsers";
import AdminUsersUpdate from "./components/Admin/AdminUsersUpdate";
import AdminDashboard from "./components/Admin/AdminDashboard";
import Profile from "./pages/Profile";
import ProfileEdit from "./pages/ProfileEdit";
import Login from "./components/Login";
import Signup from "./components/Admin/Signup";
import AdminSBClasses from "./components/Admin/AdminSBClasses";
import SBClasses from "./pages/SBClasses";
import ManagerLayouts from "./components/layouts/ManagerLayouts";
import SupervisorLayouts from "./components/layouts/SupervisorLayout";
import AdminBooking from "./components/Admin/AdminBooking";
import AdminBookingUpdate from "./components/Admin/AdminBookingUpdate";
import AdminBookingCalendar from "./components/Admin/AdminBookingCalendar";
import BookingForm from "./pages/BookingForm";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Calendar from "./pages/Calendar";
import AdminEmailUpdate from "./components/Admin/AdminEmailUpdate";
import AdminEmail from "./components/Admin/AdminEmail";
import AdminDeletedData from "./components/Admin/AdminDeletedData";
import PageNotFound from "./pages/PageNotFound";
import VerifyEmail from "./pages/verifyEmail";

function App() {
  const location = useLocation();

  const hideNavbarRoutes = ["/otpinput", "/admin", "/manager", "/supervisor"];
  const shouldHideNavbar = hideNavbarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    //  className="bg-orange-100"
    <div>
      {!shouldHideNavbar && <Navbar />}
      <Routes>
        {/* Page Routes selection ------------------------->>>>>>>>>> */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />
        <Route path="/bookingform" element={<BookingForm />} />
        <Route path="/sbclasses" element={<SBClasses />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profileEdit" element={<ProfileEdit />} />
        <Route path="/verifyemail/:token" element={<VerifyEmail />} />
        
        {/* Admin Selection -------------------->>>>>>>>>>>>>>> */}
        <Route path="/admin" element={<AdminLayouts />}>
          <Route path="signup" element={<Signup />} />

          <Route path="users" element={<AdminUsers />} />
          <Route path="users/:id/edit" element={<AdminUsersUpdate />} />

          <Route path="dashboard" element={<AdminDashboard />} />

          <Route path="booking" element={<AdminBooking />} />
          <Route path="booking/:id/edit" element={<AdminBookingUpdate />} />

          <Route path="booking/deletedata" element={<AdminDeletedData />} />

          <Route
            path="booking/bookingcalender"
            element={<AdminBookingCalendar />}
          />

          <Route path="adminsbclasses" element={<AdminSBClasses />} />

          <Route path="adminemail" element={<AdminEmail />} />
          <Route path="adminemail/:id/update" element={<AdminEmailUpdate />} />
        </Route>
        
        {/* Manager Selection -------------------->>>>>>>>>>>>>>> */}
        <Route path="/manager" element={<ManagerLayouts />}>
          {/* Conference Manager */}
          <Route path="booking" element={<AdminBooking />} />
          <Route path="booking/:id/edit" element={<AdminBookingUpdate />} />
          <Route path="booking/deletedata" element={<AdminDeletedData />} />
          <Route
            path="booking/bookingcalender"
            element={<AdminBookingCalendar />}
          />
        </Route>
        {/* Supervisor Selection -------------------->>>>>>>>>>>>>>> */}
        <Route path="/supervisor" element={<SupervisorLayouts />}>
          <Route path="adminsbclasses" element={<AdminSBClasses />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
