import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../store/auth";
import { confirmAlert } from "react-confirm-alert";
import { ImSpinner } from "react-icons/im";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../../utils/api";

const AdminBookingCalendar = () => {
  const { authorizationToken } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    facilitator: "",
    email: "",
    CC: "",
    requestType: "",
    hallName: "",
    date: "",
    startTime: "",
    endTime: "",
    count: "",
    purpose: "",
    recurrence: "",
    recurrenceEndDate: "",
  });

  const ccEmail = (formData.CC || "").split(',').map(email => email.trim());
  console.log(ccEmail);

  const hallOptions = [
    "Conference Room",
    "Bhaktivedanta Hall",
    "Bhaktisiddhanta Hall",
    "Gopalji Hall",
    "BACE Class Rooms (Nitai room included)",
    "Laxmi Narayan Hall",
    "Bhaktivinoda Hall (New) (Old bookings in BACE Class Room Tab)",
  ];

  const requestType = [
    "Govindas Booking",
    "BACE Educational Course",
    "Preaching Program",
    "Meeting",
    "Festival Preparations ",
  ];

  const recurrenceOptions = ["Daily", "Weekly", "Monthly"];

  const hallCapacity = {
    "Conference Room": 100,
    "Bhaktivedanta Hall": 300,
    "Bhaktisiddhanta Hall": 200,
    "Gopalji Hall": 50,
    "BACE Class Rooms (Nitai room included)": 100,
    "Laxmi Narayan Hall": 75,
    "Bhaktivinoda Hall": 150,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // If the field being updated is 'hallName', update the 'count' field based on the selected hall
      if (name === "hallName") {
        updatedData.count = hallCapacity[value] || "";
      }

      return updatedData;
    });
  };

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = async (e) => {
    setIsSubmited(true);
    e.preventDefault();

    if (formData.recurrence && !formData.recurrenceEndDate) {
      toast.error("Please Select Recurrence End Date .");
      setIsSubmited(false);
      return;
    }

    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure you want to submit?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const {
                name,
                phoneNo,
                facilitator,
                email,
                CC,
                requestType,
                hallName,
                date,
                startTime,
                endTime,
                count,
                purpose,
                recurrence,
                recurrenceEndDate,
              } = formData;

              const formattedDate = formatDateToDDMMYYYY(date);
              const formattedRecurrenceEndDate = recurrenceEndDate
                ? formatDateToDDMMYYYY(recurrenceEndDate)
                : null;

              const config = {
                headers: {
                  Authorization: authorizationToken,
                },
              };

              const response = await axios.post(
                `${API_BASE_URL}/api/booking/bookingaddtocalender`,
                {
                  name,
                  phoneNo,
                  facilitator,
                  email,
                  CC:ccEmail,
                  requestType,
                  hallName,
                  date: formattedDate,
                  startTime,
                  endTime,
                  count,
                  purpose,
                  recurrence,
                  recurrenceEndDate: formattedRecurrenceEndDate,
                },
                config
              );

              if (response.status === 201) {
                setFormData({
                  name: "",
                  phoneNo: "",
                  facilitator: "",
                  email: "",
                  CC: "",
                  requestType: "",
                  hallName: "",
                  date: "",
                  startTime: "",
                  endTime: "",
                  count: "",
                  purpose: "",
                  recurrence: "",
                  recurrenceEndDate: "",
                });
                toast.success("Successfully booked a Hall");
              } else {
                toast.error("Booking failed");
              }
            } catch (error) {
              const errorMessage =
                error.response?.data?.message ||
                "An error occurred during booking";
              console.error("Error:", errorMessage);
              toast.error(errorMessage);
            } finally {
              setIsConfirmLoading(false); // Clear loading state
              setIsSubmited(false); // Clear approving state
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            setIsSubmited(false);
          },
        },
      ],
    });
  };

  const calendardata = [
    {
      label: "Tab 1",
      value: "conference-room",
      src: "https://calendar.google.com/calendar/embed?src=d623ba6cded4ad25c01be956913ade73d57decc21fd6d07b16c3dff36406cc5d%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 2",
      value: "bhaktivedanta-hall",
      src: "https://calendar.google.com/calendar/embed?src=77a6194605e843ee7cf85475c9450f3aec8d787615a36dceb67f0ee94168bd49%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 3",
      value: "bhaktisiddhanta-hall",
      src: "https://calendar.google.com/calendar/embed?src=edaa6e1034bf94afd350955644ac544222cd77060da725bcf0219e8229c1bb7c%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 4",
      value: "gopalji-hall",
      src: "https://calendar.google.com/calendar/embed?src=391be59d0466d0ccf3606d3086a9b2368621a5b8ccae847f2e151e4da058a134%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 5",
      value: "bace-class-rooms",
      src: "https://calendar.google.com/calendar/embed?src=da821b950210929e172a782be2010657bb0bfb48784777f6440e914c0b247282%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 6",
      value: "laxmi-narayan-hall",
      src: "https://calendar.google.com/calendar/embed?src=5340a928e6268fb56f9aa0399cd7ee720f2a7f9e0630528fcfe398c5cffa73a1%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
    {
      label: "Tab 7",
      value: "bhaktivinoda-hall",
      src: "https://calendar.google.com/calendar/embed?src=8aae3f6002bac136d8c605cd3905d2bba586155cfaa15ec0b4bcaf4fbb1dbc55%40group.calendar.google.com&ctz=Asia%2FKolkata",
    },
  ];

  const [activeTab, setActiveTab] = useState(calendardata[0].value);

  return (
    <>
      {isLoading || isSubmited ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner-border text-primary" role="status">
            <ImSpinner className="w-10 h-10 animate-spin" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row justify-center items-center min-h-screen">
          <div className="md:w-1/2 md:pr-4">
            <h1 className="text-4xl font-semibold text-center py-10 md:py-[2rem]">
              Manager Booking Form
            </h1>
            <form
              onSubmit={handleSubmit}
              className="shadow-md p-6 item-center m-auto rounded-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="name" className="block mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="phoneNo" className="block mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNo"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
                <div className="mb-4">
                    <label htmlFor="CC" className="block mb-2">
                      CC Emails (comma-separated):
                    </label>
                    <input
                      type="text"
                      id="CC"
                      name="CC"
                      value={formData.CC}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded px-4 py-2"
                    />
                  </div>
                <div>
                  <label htmlFor="facilitator" className="block mb-1">
                    Facilitator's Name
                  </label>
                  <input
                    type="text"
                    id="facilitator"
                    name="facilitator"
                    value={formData.facilitator}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                  />
                </div>
                <div>
                  <label htmlFor="date" className="block mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="startTime" className="block mb-1">
                    Start Time
                  </label>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="endTime" className="block mb-1">
                    End Time
                  </label>
                  <input
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="hallName" className="block mb-1">
                  Hall Name
                </label>
                <select
                  id="hallName"
                  name="hallName"
                  value={formData.hallName}
                  onChange={handleChange}
                  className="w-full border block border-gray-300 rounded px-4 py-2"
                  required
                >
                  <option value="">Select Hall</option>
                  {hallOptions.map((hall) => (
                    <option key={hall} value={hall}>
                      {hall}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="count" className="block mb-1">
                    Count
                  </label>
                  <input
                    type="text"
                    id="count"
                    name="count"
                    value={formData.count}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="requestType" className="block mb-1">
                    Request Type
                  </label>
                  <select
                    id="requestType"
                    name="requestType"
                    value={formData.requestType}
                    onChange={handleChange}
                    className="w-full border block border-gray-300 rounded px-4 py-2"
                    required
                  >
                    <option value="">Select </option>
                    {requestType.map((req) => (
                      <option key={req} value={req}>
                        {req}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="recurrence" className="block mb-1">
                    Recurrence
                  </label>
                  <select
                    id="recurrence"
                    name="recurrence"
                    value={formData.recurrence}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                  >
                    <option value="">None</option>
                    {recurrenceOptions.map((recurrence) => (
                      <option key={recurrence} value={recurrence}>
                        {recurrence}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1">
                  <label className="block mb-1">Recurrence End Date</label>
                  <input
                    type="date"
                    id="recurrenceEndDate"
                    name="recurrenceEndDate"
                    value={formData.recurrenceEndDate}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-4 py-1"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="purpose" className="block mb-1">
                  Purpose
                </label>
                <textarea
                  type="textarea"
                  id="purpose"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded px-4 py-2"
                  required
                />
              </div>
              <div className="mt-4 text-center">
                <button
                  type="submit"
                  className="bg-orange-200 text-white rounded px-6 py-2 hover:bg-orange-600 transition-colors duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div className="w-full md:w-1/2 p-8">
            <div className="mb-4">
              <nav className="flex space-x-4">
                {calendardata.map((tab) => (
                  <button
                    key={tab.value}
                    onClick={() => setActiveTab(tab.value)}
                    className={`${
                      activeTab === tab.value
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-700"
                    } py-2 px-4 rounded`}
                  >
                    {tab.label}
                  </button>
                ))}
              </nav>
            </div>

            <div className="calendar-container mt-4">
              {calendardata.map(
                (tab) =>
                  activeTab === tab.value && (
                    <iframe
                      key={tab.value}
                      src={tab.src}
                      style={{ border: 0 }}
                      width="600"
                      height="600"
                      frameBorder="0"
                      scrolling="no"
                      title={tab.label}
                    ></iframe>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminBookingCalendar;
