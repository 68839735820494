import React, { useState } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../store/auth";
import logo from "../Images/Logo.png";

function NavBar() {
  const [nav, setNav] = useState(false);
  const { isLoggedIn, LogoutUser } = useAuth();

  const navSections = [
    {
      nav: "Home",
      path: "/",
    },
    {
      nav: "SBClasses ",
      path: "/sbclasses",
    },
  ];

  return (
    <div className="w-full fixed top-0 z-50 bg-black">
      <div className="flex justify-between items-center w-full h-[80px] px-4">
        <div>
          <NavLink to="/">
            <img className="w-[30%] mx-10" src={logo} alt="logo" />
          </NavLink>
        </div>

        <ul className="hidden md:flex text-center">
          {navSections.map(({ nav, path }) => (
            <li
              key={nav}
              className="md:px-4 md:mx-6 text-center cursor-pointer capitalize font-medium text-white hover:scale-105 hover:text-orange-200 duration-200"
              onClick={() => {
                setNav(false);
              }}
            >
              <NavLink to={path}>{nav}</NavLink>
            </li>
          ))}
        </ul>

        <ul className="hidden md:flex items-center space-x-4">
          {isLoggedIn ? (
            <>
              <li>
                <Link
                  to="/profile"
                  className="bg-black text-white p-2 mx-1 md:px-8 md:py-[4px] rounded-full"
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="bg-black text-white p-2 mx-1 md:px-8 md:py-[4px] rounded-full"
                  onClick={LogoutUser}
                >
                  Logout
                </Link>
              </li>
            </>
          ) : (
            <li>
              <Link
                to="/login"
                className="bg-black text-white p-2 md:px-8 md:py-[5px] rounded-full"
              >
                Login
              </Link>
            </li>
          )}
        </ul>

        <div
          onClick={() => setNav(!nav)}
          className="cursor-pointer pr-4 z-10 text-white md:hidden"
        >
          {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
        </div>

        {nav && (
          <ul
            className="flex flex-col justify-center items-center
              absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800
              text-white md:hidden"
          >
            {navSections.map(({ nav, path }) => (
              <li
                key={nav}
                className="text-4xl cursor-pointer px-4 py-8"
                onClick={() => {
                  setNav(false);
                }}
              >
                <NavLink to={path}>{nav}</NavLink>
              </li>
            ))}
            {isLoggedIn ? (
              <li
                className="text-4xl cursor-pointer px-4 py-8"
                onClick={() => {
                  setNav(false);
                  LogoutUser();
                }}
              >
                Logout
              </li>
            ) : (
              <li className="text-4xl cursor-pointer px-4 py-8">
                <Link to="/login" onClick={() => setNav(false)}>
                  Login
                </Link>
              </li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default NavBar;
