import { useEffect, useState } from "react";
import { useAuth } from "../../store/auth";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import API_BASE_URL from "../../utils/api";

const AdminUsersUpdate = () => {
  const { authorizationToken, user } = useAuth();
  const params = useParams();

  const [formData, setFormData] = useState({
    username: "",
    department: "",
    phoneNo: "",
    email: "",
    role: "",
    updaterole: "",
  });

  const [error, setError] = useState("");

  const getSingleUserData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/admin/users/${params.id}`,
        {
          headers: {
            Authorization: authorizationToken,
          },
        }
      );

      if (response.status === 200) {
        // console.log(response.data);
        setFormData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleUserData();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userBeingEditedId = params.id;

    if (user.id === userBeingEditedId) {
      toast.error("Admin cannot change their own role");
      return; // Stop execution if the condition is true
    }

    confirmAlert({
      title: "Confirm to Update",
      message: "Are you sure you want to update this user?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.put(
                `${API_BASE_URL}/api/admin/users/${params.id}`,
                formData,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );

              if (response.status === 200) {
                setFormData(response.data);
                toast.success("Updated Successfully");
              } else {
                toast.error("User data not updated");
              }
            } catch (error) {
              console.error("Error updating user:", error);
              toast.error("Failed to update user");
            }

            try {
              const response = await axios.put(
                `${API_BASE_URL}/api/admin/users/updateUserRole`,
                formData,
                {
                  headers: {
                    Authorization: authorizationToken,
                  },
                }
              );
              // console.log(response);

              if (response.status === 200) {
                setFormData(response.data);
                toast.success("Role updated successfully");
              } else {
                toast.error("Role not updated");
              }
            } catch (error) {
              console.error("Error updating role:", error);
              toast.error("Failed to update role");
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // Do nothing if user clicks No
          },
        },
      ],
    });
  };

  return (
    <div className="flex w-full items-center justify-center  bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white w-[500px] p-8 shadow-md"
      >
        <div>
          <h1 className="block text-gray-700 text-xl font-bold mb-8 ">
            Update User Data
          </h1>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Username:
          </label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInput}
            className={`border ${
              error.username ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.username} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="department"
          >
            Department:
          </label>
          <input
            type="text"
            name="department"
            value={formData.department}
            onChange={handleInput}
            className={`border ${
              error.department ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.department} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="phoneNo"
          >
            PhoneNO:
          </label>
          <input
            type="text"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleInput}
            className={`border ${
              error.phoneNo ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.phoneNo} </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInput}
            className={`border ${
              error.email ? "border-red-500" : "border-gray-300"
            } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
          />
          <div className="text-red-500 my-2"> {error.email} </div>
        </div>

        {user.id !== params.id && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="role"
              >
                Role:
              </label>
              <input
                type="text"
                name="role"
                value={formData.role}
                onChange={handleInput}
                className={`border ${
                  error.role ? "border-red-500" : "border-gray-300"
                } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
              />
              <div className="text-red-500 my-2"> {error.role} </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="role"
              >
                Update Role:
              </label>
              <select
                name="role"
                value={formData.role}
                onChange={handleInput}
                className={`border ${
                  error.role ? "border-red-500" : "border-gray-300"
                } px-4 py-1 w-[100%] focus:outline-none focus:border-black`}
              >
                <option value="">Select Role</option>
                <option value="admin">admin</option>
                <option value="manager">manager</option>
                <option value="supervisor">supervisor</option>
                <option value="user">user</option>
              </select>
              {error.role && (
                <div className="text-red-500 my-2">{error.role}</div>
              )}
            </div>
          </>
        )}

        <button
          type="submit"
          className="bg-black text-white py-2 px-8 my-3 rounded-full hover:bg-gray-800 focus:outline-none focus:bg-gray-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default AdminUsersUpdate;
